<div class="loading-overlay-wrapper" *ngIf="loading.isActive$ | async">
  <mat-spinner class="spinner"></mat-spinner>
</div>
<mat-toolbar color="primary">
  <button mat-icon-button (click)="toggleNav()">
    <mat-icon>menu</mat-icon>
  </button>
  <span>Render Static</span>
  <div class="flex-1">
  </div>
  <div *ngIf="auth.currentUser$ | async as currentUser" class="flex gap-1 items-center">
    <div class="font-normal hidden sm:block">Welcome</div>
    <button mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-sidenav-container *ngIf="navState$ | async as nav" class="flex-1" autosize>
  <mat-sidenav #sidenav [mode]="nav.isMobile ? 'push' : 'side'" [opened]="nav.isOpen">
    <ng-template #anon>
      <ul class="sidenav">
        <li>
          <a [routerLink]="['/login']" [queryParams]="{ initial: 'register' }"><mat-icon>bolt</mat-icon>Register</a>
        </li>
        <li>
          <a [routerLink]="['/login']"><mat-icon>login</mat-icon>Login</a>
        </li>
        <li>
          <a [routerLink]="['/contact']"><mat-icon>contact_support</mat-icon>Contact Us</a>
        </li>
      </ul>
    </ng-template>

    <div *ngIf="auth.isAuthed$ | async as isAuthed; else anon">
      <renderstatic-account-select></renderstatic-account-select>

      <ul class="sidenav">
        <li>
          <a [routerLink]="['/dashboard']"><mat-icon>dashboard</mat-icon>Dashboard</a>
        </li>
        <li>
          <a [routerLink]="['/websites']"><mat-icon>language</mat-icon>Websites</a>
        </li>
        <li>
          <a [routerLink]="['/renders']"><mat-icon>code</mat-icon>Renders</a>
        </li>
        <li>
          <a [routerLink]="['/account']"><mat-icon>credit_card</mat-icon>Account</a>
        </li>
        <li>
          <a [routerLink]="['/contact']"><mat-icon>contact_support</mat-icon>Contact Us</a>
        </li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="flex flex-col px-4 py-4 gap-4 h-full">
      <div class="flex-1">
        <router-outlet></router-outlet>
      </div>
      <div class="flex-initial text-center">
        <p>
          <a class="text-link" [routerLink]="['/privacy-policy']">Privacy Policy</a> |
          <a class="text-link" [routerLink]="['/terms-of-use']">Terms & Conditions</a>
        </p>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>