<div class="max-w-md" *ngIf="initialState$ | async as initialState">
  <amplify-authenticator [loginMechanisms]="['email']" [socialProviders]="['google']" [signUpAttributes]="['given_name', 'family_name']" [formFields]="formFields" [initialState]="initialState">
    <ng-template
      amplifySlot="authenticated"
      let-user="user"
      let-signOut="signOut"
    >
      <h2>Welcome, {{ user.username }}!</h2>
      <p>
        <a mat-raised-button color="primary" [routerLink]="['/dashboard']" class="text-link">Continue to Dashboard</a>
      </p>
    </ng-template>
  </amplify-authenticator>
</div>
