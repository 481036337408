import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parseISO } from 'date-fns';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class DatesInterceptor implements HttpInterceptor {

    private readonly isoDate = /^\d{4}-\d\d-\d\dT00:00:00Z?$/
    private readonly isoDateTime = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/

    public isIsoDateTime(value: string): boolean {
        return this.isoDateTime.test(value)
    }

    public isIsoDate(value: string): boolean {
        return this.isoDate.test(value)
    }

    public convertIsoDate(value: unknown): Date | unknown {
        if (typeof value === 'string' && this.isIsoDate(value)) {
            return parseISO(value.replace('T00:00:00Z', ''))
        }
        if (typeof value === 'string' && this.isIsoDateTime(value)) {
            return parseISO(value)
        }
        return value
    }

    public convertArray(value: Array<unknown>): Array<unknown> {
        return value.map(m => this.convertObject(m))
    }

    public convertObject(object: unknown): unknown {
        if (object instanceof Array) {
            return this.convertArray(object)
        }

        if (object instanceof Object) {
            const entries = Object.entries(object)
            if (entries.length > 0) {
                return entries
                    .map(([key, value]) => {
                        if (value instanceof Array) {
                            return { [key]: this.convertArray(value) }
                        }
                        if (value instanceof Object) {
                            return { [key]: this.convertObject(value) }
                        }
                        return { [key]: this.convertIsoDate(value) }
                    })
                    .reduce((previous, current) => Object.assign(previous, current, {}))
            }
            return object
        }
        return this.convertIsoDate(object)
    }

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const matches = req.url.startsWith(environment.api_base)

        if (matches) {
            return next.handle(req).pipe(
                map((event: HttpEvent<unknown>) => {
                    if (event instanceof HttpResponse) {
                        return event.clone({ body: this.convertObject(event.body) })
                    }
                    return event;
                })
            );
        }

        return next.handle(req);
    }
}