import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class LoadingService {
    private readonly processes = new BehaviorSubject<string[]>([])

    public readonly isProcessing$ = this.processes.pipe(
        map(processes => processes.length > 0),
        distinctUntilChanged()
    )
    public readonly isNav$: Observable<boolean>
    public readonly isActive$: Observable<boolean>

    constructor(
        private readonly router: Router
    ) {
        this.isNav$ = this.router.events.pipe(
            filter(f => f instanceof NavigationStart || f instanceof NavigationEnd || f instanceof NavigationCancel || f instanceof NavigationError),
            map(m => m instanceof NavigationStart)
        );

        this.isActive$ = combineLatest([this.isNav$, this.isProcessing$]).pipe(
            map(([isNav, isProcessing]) => isNav || isProcessing)
        )
    }

    loadingStart(process = 'default'): void {
        if (this.processes.value.indexOf(process) < 0) {
            this.processes.next([...this.processes.value, process])
        }
    }

    loadingFinish(process = 'default'): void {
        this.processes.next(this.processes.value.filter(f => f != process))
    }

}
