import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject, map, merge, shareReplay, take } from 'rxjs';
import { AuthService } from './modules/core/services/auth.service';
import { LoadingService } from './services/loading.service';

interface NavState {
  isOpen: boolean
  isMobile: boolean
}


@Component({
  selector: 'renderstatic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public readonly isMobile$: Observable<BreakpointState>
  private readonly navStateSubject = new Subject<NavState>()
  public readonly navState$: Observable<NavState>

  constructor(
    public readonly auth: AuthService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly router: Router,
    public readonly loading: LoadingService
  ) {
    this.isMobile$ = this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ])

    this.navState$ = merge(
      this.isMobile$.pipe(
        map(matches => ({
          isMobile: matches.matches,
          isOpen: !matches.matches
        }))
      ),
      this.navStateSubject
    ).pipe(
      shareReplay(1)
    )

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.navState$.pipe(
          take(1)
        ).subscribe(state => {
          if (state.isMobile) {
            this.navStateSubject.next({
              ...state,
              isOpen: false
            })
          }
        })
      }
    });
  }


  toggleNav() {
    this.navState$.pipe(
      take(1)
    ).subscribe(state => {
      this.navStateSubject.next({
        ...state,
        isOpen: !state.isOpen
      })
    })

  }

  public logout() {
    this.auth.logout().then(() => {
      this.router.navigate(['/'])
    })
  }
}
