import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { AuthService } from '../modules/core/services/auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthService) { }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(environment.api_base) && !req.headers.has('Authorization')) {
      return this.auth.getCurrentSession().pipe(
        take(1),
        switchMap((session) => {
          if (session) {
            return next.handle(req.clone({
              setHeaders: {
                Authorization: 'Bearer ' + session.tokens?.accessToken,
              },
            }));
          }
          return next.handle(req)
        })
      );
    }
    return next.handle(req)
  }
}