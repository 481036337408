<h1>Welcome to Render Static!</h1>

<p>Render Static provides a flexible and affordable solution to enable SEO for websites built on Angular or other SPA
    (Single Page Application) frameworks. Render Static easily plugs into your existing architecture. Whenever a search
    engine or social media bot requests a URL from your website, Render Static will return a fully rendered static
    version of the requested page.</p>

<p>Our team has spent years building Angular websites fully hosted on AWS CloudFront. The technology behind
    Render Static was developed through years of experience optimizing SEO for these websites. We finally decided it was
    time to roll this tech out for other developers to benfit from our experience. Render Static is created by engineers
    for engineers!</p>

<h2>Features</h2>
<ul class="list-disc list-inside">
    <li>Easy to use dashboard with deep insights into service provided.</li>
    <li>Integration with hosted XML sitemaps to pre-cache each page in your website. Sitemaps are read daily.</li>
    <li>Custom cache TTL for each website and automatic re-rendering and re-caching of each page upon cache expiry. Cache age can range from 60 seconds to 31 days.</li>
    <li>Unlimited website domains per account.</li>
    <li>Unlimited cached pages per account.</li>
    <li>Helpful docs for integration with AWS CloudFront.</li>
    <li>Our team will help you integrate Render Static with your environment.</li>
</ul>

<h2>Our Pricing is Simple</h2>
<em>Free Tier</em>
<ul>
    <li>First 1,000 Renders Free</li>
    <li>First 10,000 Cache Hits Free</li>
</ul>

<em>After Free Tier</em>
<ul>
    <li>$0.001 per Render</li>
    <li>$0.0001 per Cache Hit</li>
</ul>

<p>Billing is based on actual usage at the end of each month.</p>

<p class="font-bold">Credit Card is NOT required to try us out.</p>

<p>
    <a mat-raised-button color="primary" [routerLink]="['/login']" [queryParams]="{ initial: 'register' }">Signup to get
        started today!</a>
</p>