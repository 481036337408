import { Component } from '@angular/core';

@Component({
  selector: 'renderstatic-module-parent',
  templateUrl: './module-parent.component.html',
  styleUrls: ['./module-parent.component.scss']
})
export class ModuleParentComponent {

}
