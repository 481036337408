import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { environment } from '../../environments/environment';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private readonly loading: LoadingService) { }

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const trigger = req.url.startsWith(environment.api_base) || req.url.startsWith('https://cognito-identity.us-east-1.amazonaws.com')
        const loadingHash = `${req.urlWithParams}|${JSON.stringify(req.body)}`;
        if (trigger) {
            this.loading.loadingStart(loadingHash)
        }

        return next.handle(req).pipe(
            finalize(() => {
                if (trigger) {
                    this.loading.loadingFinish(loadingHash)
                }
            })
        )
    }
}