
import { platformBrowser } from '@angular/platform-browser';
import { AppModule } from './app/app.module';
import { amplify } from './environments/environment';
import { Amplify } from 'aws-amplify';

Amplify.configure(amplify);

platformBrowser().bootstrapModule(AppModule)
  .catch(err => console.error(err));
