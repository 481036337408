import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'renderstatic-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private readonly route: ActivatedRoute
    )
  {

  }

  public readonly initialState$: Observable<"signUp" | "signIn"> = this.route.queryParams.pipe(
    map(params=>params['initial']),
    map(initial => {
      if(initial == 'register')
      {
        return 'signUp'
      }
      return 'signIn'
    })
  )

  public formFields = {
    signUp: {
      given_name: {
        order: 1,
        label: 'First Name'
      },
      family_name: {
        order: 2,
        label: 'Last Name'
      },
      email: {
        order:3
      },
      password: {
        order: 4
      },
      confirm_password: {
        order: 5
      }
    },
  }
}
