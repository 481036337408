import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, shareReplay, tap } from 'rxjs';

import { AccountService } from '../../modules/core/services/account.service';

@Component({
  selector: 'renderstatic-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.scss']
})
export class AccountSelectComponent {

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly account_service: AccountService,
  ){

  }

  public readonly account_form$ = this.account_service.currentAccount$.pipe(
    map(account => new FormGroup({
      account_id: new FormControl(account?.account_id)
    })),
    tap(form => {
      form.valueChanges.subscribe(data => {
        if(data.account_id)
        {
          this.account_service.setAccount(data.account_id)
          this.router.navigate(this.route.snapshot.url)
        }
      })
    }),
    shareReplay(1)
  )

}
