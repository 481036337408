import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccountService } from '../modules/core/services/account.service';
import { REQUIRES_ACCOUNT_TOKEN } from '../modules/core/services/api.service';

@Injectable()
export class AccountInterceptor implements HttpInterceptor {
  constructor(private readonly account_service: AccountService) { }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(environment.api_base) && req.context.get(REQUIRES_ACCOUNT_TOKEN)) {
      return this.account_service.currentAccount$.pipe(
        take(1),
        switchMap((account) => {
          if (account) {
            return next.handle(req.clone({
              setHeaders: {
                'renderstatic-account': account.account_id
              },
            }));
          }
          return next.handle(req)
        })
      );
    }
    return next.handle(req)
  }
}