import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { authGuard } from './guards/auth.guard'
import { ModuleParentComponent } from './components/module-parent/module-parent.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: ModuleParentComponent,
    canActivateChild: [authGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'websites',
    component: ModuleParentComponent,
    canActivateChild: [authGuard],
    loadChildren: () => import('./modules/websites/websites.module').then(m => m.WebsitesModule)
  },
  {
    path: 'renders',
    component: ModuleParentComponent,
    canActivateChild: [authGuard],
    loadChildren: () => import('./modules/renders/renders.module').then(m => m.RendersModule)
  },
  {
    path: 'account',
    component: ModuleParentComponent,
    canActivateChild: [authGuard],
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'contact',
    component: ModuleParentComponent,
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: '',
    component: WelcomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
